import * as React from 'react'
import { terms } from '/src/styles/villkor-comp.module.css'

const Anvandarvillkor = () => {
	return (<div className={terms}>
            
            <a id="anvandarvillkor"></a>
			<h2>Användarvillkor &amp; personuppgiftspolicy</h2>

            <p>Uppdaterad: 2021-01-17</p>
            <h3>1) Definitioner</h3>
            <h4>a) "Tjänsten"</h4>
            <p>Appen och webben "Brfify" anges i dessa villkor som "Brfify" eller "tjänsten".</p>
            <h4>b) "Tjänsteägare"</h4>
            <p>ägare av tjänsten anges i villkoren som "tjänsteägare".</p>
            <h4>c) "Användare"</h4>
            <p>Du som godkänner dessa villkor anges i villkoren som "användare", "du" eller "medlem".</p>
            <h4>d) "Brf"</h4>
            <p>Den bostadsrättsförening du är medlem i och som är ansluten till Brfify anges i villkoren som "Brf" eller "Brf:en".</p>
            <h3>2) Tjänsten</h3>
            <h4>a) Kostnad &amp; förpliktelser</h4>
            <p>Tjänsten är för slutanvändare kostnadsfri att använda och för tjänstägare utan några förpliktelser. Exempelvis garanteras ingen särskild leverans, tillgänglighet eller kvalitet.</p>
            <h4>b) Användning</h4>
            <p>Tjänsten får endast användas på tilltänkt sätt genom officiella appar, webb eller API, all annan användning är förbjuden och kan innebära blockering från tjänsten. Det är också förbjudet att på något sätt försöka påverka eller manipulera resultat eller data.</p>
            <h4>c) Villkor</h4>
            <p>Godkännande av villkor i sin helhet är nödvändigt för användande av tjänsten.</p>
            <p>Dessa villkor gäller tillsvidare och tjänsteägare har rätt att ändra gällande villkor. Villkorsändring träder i kraft 30 dagar efter det att ändringen har publicerats på tjänstens webbplats. Villkorsändring ska meddelas användare skriftligen via e-post i samband med publicering på webbplats. För det fall användare vid registrering inte har angett en fungerande e-postadress är användaren själv skyldig att hålla sig informerad om villkorsändringar.</p>
            <h3>3) Personuppgifter &amp; GDPR</h3>
            <h4>a) Syfte &amp; anledning till lagring</h4>
            <p>Syftet med lagring av personuppgifter i tjänsten är att kunna leverera grunstjänster och funktioner. Med stor respekt för personlig integritet lagras endast precis nödvändiga uppgifter för att kunna använda tjänsten (uppgiftsminimering). Exempelvis så krävs endast offentliga uppgiften lägenhetsnummer för att kunna använda tjänsten, inte e-postadress eller telefonnummer osv.</p>
            <h4>b) Uppgifter som lagras</h4>
            <p>
                Följande personuppgifter lagras:<br/>
                &nbsp;• <b>Lägenhetsnummer</b> (offentlig uppgift och information som din Brf redan angivet i tjänsten).<br/>
                &nbsp;• <b>IP-adress vid registrering</b>/inloggning med inbjudan (av säkerhetsskäl och rensas automatiskt).<br/>
                &nbsp;• <b>Datum</b> när dina inloggningar skapades och när de senast användes.<br/>
                &nbsp;• <b>Bokningshistorik</b> när du genomför bokningar i appen. Inaktuella/passerade bokningar rensas automatiskt.<br/>
                &nbsp;• <b>Köhistorik</b> när du ställer dig i kö i appen. Inaktuella/passerade köer rensas automatiskt.<br/>
                &nbsp;• <b>Notis-ID</b> om du själv godkänt att få notiser.<br/>
                &nbsp;• Annan information du eller Brf <b>själva</b> anger/genererar i tjänsten.
            </p>
            <h4>c) Lagringstid</h4>
            <p>Om inget annat angivet i punkt b) lagras personuppgifter till dess att de inte längre behövs, att du eller Brf begär att uppgifterna tas bort eller att tjänsten läggs ner.</p>
            <h4>d) Delning</h4>
            <p>Inga personuppgifter delas med någon part än Brf om inte lag eller reglering kräver det. All kommunikation sker krypterat och tillgång till databas-server är mycket strikt begränsat. Leverantör för infrastruktur "Digital Ocean" förpliktigar sig att för sin del följa GDPR och har inte direkt tillgång till databas. Läs ingått "DPA" (Data Processing Agreement) här»</p>
            <h4>e) Lagring inom EU</h4>
            <p>Personuppgifter lagras endast på servrar som fysiskt befinner sig inom EU. Se punkt d) för DPA.</p>
            <h4>f) Klagomål</h4>
            <p>Du har rätt att lämna klagomål till tillsynsmyndigheten Datainspektionen.</p>
            <h4>g) Ta tillbaka samtycke</h4>
            <p>Du har rätt att ta tillbaka ditt samtycke (vilket du lämnar genom att godkänna dessa villkor) när som helst. Genom att ta tillbaka ditt samtycke raderas alla dina personuppgifter, förutsatt att lag eller reglering inte kräver annat.</p>
            <h4>h) Grundläggande dataskyddsrättigheter</h4>
            <p>
                Som registrerad har du flera rättigheter, vilket du i sin helhet kan läsa om hos myndigheten Datainspektionen.&nbsp;Exempel på rättigheter är följande och begäran sker genom meddelande till hej@brfify.se:
                <br/>
                • Du har rätt till rättelse av dina personuppgifter när som helst.
                <br/>
                • Du har rätt till att bli bortglömd (att dina personuppgifter tas bort) när som helst. De uppgifter som kan tas bort är de angivna i punkt b).
            </p>
            <h4>i) Rättslig grund</h4>
            <p>Rättslig grund för lagring och behandling av personuppgifter är ditt samtycke till dessa villkor i sin helhet.</p>
            <h4>j) Kontaktuppgifter</h4>
            <p>Välkommen med frågor eller begäran till hej@brfify.se</p>

    </div>)
}

export default Anvandarvillkor