import * as React from 'react'
import Anvandarvillkor from '/src/components/villkor'

const TermsPage = () => {
	return (
		<div style={{margin: '10px 15px'}}>

			<p style={{fontFamily: 'sans-serif'}}>Genom att godkänna dessa villkor samtycker du till villkoren i sin helhet, inklusive att personuppgifter kring dig lagras och behandlas inom ramen för tjänsten.</p>

			<p>&nbsp;</p>
			<hr style={{border: '1px solid gray'}} />
			<p>&nbsp;</p>

			<Anvandarvillkor></Anvandarvillkor>

			<p>&nbsp;</p>
			<hr style={{border: '1px solid gray'}} />
			<p>&nbsp;</p>

		</div>
	)
}

export default TermsPage